<template>
  <got-blocked v-if="!user || !user.vbl">
    <span class="title">
      We apologize, but this page is being worked on.
    </span>
  </got-blocked>
  <v-container fluid v-else class="pt-0">
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          append-icon="fas fa-search"
          label="Search"
          hide-details
          v-model="search"
          color="color3"
        ></v-text-field>
      </v-col>
    </v-row>
    <loading-bar :value="loading"></loading-bar>
    <v-data-iterator
      :items="organizations"
      :options.sync="pagination"
      :search="search"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="6" sm="6" md="4"
          >
            <organization-card :organization="item"></organization-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import OrganizationCard from '@/components/Organization/FlashCard.vue'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      organizations: [],
      search: null,
      pagination: { itemsPerPage: 12 },
      rowsPerPageItems: [12, 24, 36]
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    loadOrganizations () {
      this.loading = true
      this.$VBL.get.organizations()
        .then(response => {
          this.organizations = response.data
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    OrganizationCard
  },
  mounted () {
    this.loadOrganizations()
  }
}
</script>

<style scoped>
.flexcard {
  cursor: pointer;
}
.social {
  border-radius: 15px;
}
.social .v-icon {
  font-size: 30px !important;
}
.social .v-icon.fa-twitter,
.social .v-icon.fa-snapchat-ghost {
  font-size: 24px !important;
}
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}
.fa-facebook-f:before {
  margin-left: 6px;
  margin-top: 6px;
}
</style>
